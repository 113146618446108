/*
* A common place to set, get and delete local storage items used in the login/register/profile process
* This reduces the risk of misspelling strings and making other mistakes
*/

import {JanrainConstants} from '../../../adapters/helpers/Constants';
import getConfig from 'next/config';
import {redirectTo} from './redirect.helpers';

export const getOralbProductListFromLocalStorage = (uuid) => {
    let key = JanrainConstants.productListLocalStorageKeyPrefix + uuid;
    return localStorage.getItem(key) || '[]';
};

export const setOralbProductListToLocalStorage = (productList, uuid) => {
    let key = JanrainConstants.productListLocalStorageKeyPrefix + uuid;
    localStorage.setItem(key, productList);
};

export const getOralbBenefitPreferenceListFromLocalStorage = (uuid) => {
    let key = JanrainConstants.benefitListLocalStorageKeyPrefix + uuid;
    return localStorage.getItem(key) || '[]';
};

export const setOralbBenefitPreferenceListToLocalStorage = (benefitPreferenceList, uuid) => {
    let key = JanrainConstants.benefitListLocalStorageKeyPrefix + uuid;
    localStorage.setItem(key, benefitPreferenceList);
};

export const getIsCreatingAccountFromLocalStorage = () => {
    return localStorage.getItem('isCreatingAccount') || '';
};

export const setIsCreatingAccountToLocalStorage = value => {
    localStorage.setItem('isCreatingAccount', value);
};

export const deleteIsCreatingAccountFromLocalStorage = () => {
    localStorage.removeItem('isCreatingAccount');
};

export const getJanrainCaptureProfileDataFromLocalStorage = () => {
    return localStorage.getItem('janrainCaptureProfileData') || '{}';
};

export const getJanrainCaptureTokenExpiresFromLocalStorage = () => {
    return localStorage.getItem('janrainCaptureToken_Expires') || '';
};

export const getJanrainCaptureTokenFromLocalStorage = () => {
    return localStorage.getItem('janrainCaptureToken') || '';
};

export const deleteJanrainCaptureProfileDataFromLocalStorage = () => {
    localStorage.removeItem('janrainCaptureToken');
    localStorage.removeItem('janrainFailedLogins');
    localStorage.removeItem('janrainCaptureReturnExperienceData');
    localStorage.removeItem('janrainCaptureProfileData');
    localStorage.removeItem('janrainCaptureToken_Expires');
    localStorage.removeItem('janrainCaptureReturnExperienceData_Expires');
    localStorage.removeItem('janrainCaptureProfileData_Expires');
    localStorage.removeItem('janrainSessionTimeoutDate');
};

export const setSessionTimeoutFromLocalStorage = () => {
    let timeoutDate = addMinutes(new Date(), parseInt(process.env.JANRAIN_SESSION_TIMOUT_MIN));
    localStorage.setItem('janrainSessionTimeoutDate', timeoutDate);
};

export const removeSessionTimeoutFromLocalStorage = () => {
    localStorage.removeItem('janrainSessionTimeoutDate');
};

// session timeout layer added to invalidate janrain session if user is pass this timeout
export const validateCustomSessionTimeoutActive = () => {
    let sessionTimeout = localStorage.getItem('janrainSessionTimeoutDate');
    if(sessionTimeout) {
        let sessionTimeoutDate = new Date(sessionTimeout);
        if(!(sessionTimeoutDate > new Date())) {
            if (window?.janrain?.capture?.ui?.endCaptureSession) {
                window.janrain.capture.ui.endCaptureSession();
                redirectTo(process.env.JANRAIN_LOGIN_PATH);
            } else {
                deleteJanrainCaptureProfileDataFromLocalStorage();
                window.location.reload();
            }
        }
    }
};

function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes*60000);
}
